import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { rem } from "polished";

import { Inner } from "@components/layouts";

export const Footer = styled.footer`
  --footer-heading-size: ${rem("20px")};
  --footer-heading-line-height: 2.3;
  position: relative;
  z-index: 10;

  ${breakpoint("tablet-sm")`
    --footer-heading-size: ${rem("22px")};
    --footer-heading-line-height: 1.3636363636;
  `};

  ${breakpoint("tablet-lg")`
    --footer-heading-size: ${rem("28px")};
    --footer-heading-line-height: 1.1071428571;
  `}
`;

export const FooterSection = styled.div`
  max-width: 500px;

  ${breakpoint("tablet-sm")`
    flex: 1;
    max-width: calc(500px - 35px);
  `};

  &.partners-section {
    max-width: 520px;
    ${breakpoint("start", "phone-lg")`
      max-width:290px;
    `};
  }
`;

export const FooterNav = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 0 ${rem("65px")};

  ${breakpoint("tablet-sm")`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 ${rem("30px")};
  `};

  a {
    font-size: ${rem("18px")};
    line-height: 1.1111111111;
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const FooterNavItem = styled.li`
  ${breakpoint("tablet-sm")`
    width: 50%;
  `};
`;

export const FooterDetail = styled(Inner)`
  padding: ${rem("48px")} 0;
  border-top: 2px solid ${(props) => props.theme.colors.primary};

  ${breakpoint("tablet-lg")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `};

  ::before,
  ::after {
    display: block;
    position: absolute;
    top: ${rem("-9px")};
    z-index: 1;
    width: ${rem("16px")};
    height: ${rem("16px")};
    border: 2px solid ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    background: ${(props) => props.theme.colors.panels.light};
    content: "";
  }

  ::before {
    left: 0;
  }

  ::after {
    right: 0;
  }
`;

export const FooterPartners = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    width: auto;
    display: block;
    margin-bottom: ${rem("25px")};

    img {
      max-height: ${rem("60px")};
      width:auto;
    }

    @media only screen and (max-width: ${rem("550px")}) {
      margin-bottom: ${rem("15px")};
      img {
        max-height: ${rem("50px")};
      }
    }

    &:first-of-type {
      margin-right: ${rem("35px")};
      img {
        max-height: ${rem("45px")};
        @media only screen and (max-width: ${rem("550px")}) {
          max-height: ${rem("34px")};
        }
      }
    }

    &:nth-of-type(2) {
      display: inline-block;
      img {
        max-height: ${rem("45px")};
      }
      @media only screen and (max-width: ${rem("550px")}) {
        flex-basis: 100%;
        img {
          max-height: ${rem("34px")};
        }
      }

      @media only screen and (max-width: ${rem(
          "1052px"
        )}) and (min-width: ${rem("1024px")}) {
        flex-basis: 100%;
      }
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-right: ${rem("55px")};
      @media only screen and (max-width: ${rem("550px")}) {
        margin-right: ${rem("19px")};
      }
    }
  }
`;

export const FooterTitle = styled.h3`
  margin-bottom: ${rem("40px")};
  font-size: var(--footer-heading-size);
`;

export const FooterSocial = styled.div`
  padding: 0 0 ${rem("50px")};

  ${breakpoint("tablet-sm")`
    display: flex;
    align-items: center;
    padding-bottom: ${rem("35px")};
  `};
`;

export const FooterSocialTitle = styled(FooterTitle)`
  margin-bottom: ${rem("5px")};
  flex: none;

  ${breakpoint("tablet-sm")`
    margin: 0 ${rem("25px")} 0 0;
  `};
`;

export const FooterSocialList = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const FooterSocialListItem = styled.li`
  display: flex;
  align-content: center;
  padding: 0;
  width: auto;
  margin: 0;

  :not(:last-child) {
    margin-right: ${rem("33px")};
  }

  a {
    display: flex;
    align-content: flex-start;
    height: ${rem("24px")};

    :hover {
      filter: invert(55%) sepia(77%) saturate(426%) hue-rotate(136deg)
        brightness(91%) contrast(92%);
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
`;
