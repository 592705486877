import React from "react";
import { getSocialIcon } from "@helpers";
import { v4 as uuidv4 } from 'uuid';

import Link from "@components/link";

// Icons
import Twitter from "@images/twitter.svg";
import Instagram from "@images/instagram.svg";
import Linkedin from "@images/linkedin.svg";
import Youtube from "@images/youtube.svg";
import Facebook from "@images/facebook.svg";

import {
  FooterSocial,
  FooterSocialTitle,
  FooterSocialList,
  FooterSocialListItem,
} from "./style";

const socialProfileMap = {
  Twitter: Twitter,
  Instagram: Instagram,
  Linkedin: Linkedin,
  Youtube: Youtube,
  Facebook: Facebook,
};

const SocialListing = ({ title, profiles }) => {
  return (
    <FooterSocial>
      {title && <FooterSocialTitle>{title}</FooterSocialTitle>}
      <FooterSocialList>
        {profiles.map(({ socialProfile: { url, label } }) => {
          const Icon = socialProfileMap[getSocialIcon(url)];

          return (
            <FooterSocialListItem key={uuidv4()}>
              <Link url={url} target="_blank">
                <>
                  {Icon && <Icon />}
                  <span className="sr-only">{label}</span>
                </>
              </Link>
            </FooterSocialListItem>
          );
        })}
      </FooterSocialList>
    </FooterSocial>
  );
};

export default SocialListing;
