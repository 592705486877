import React from "react";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import fetch from "isomorphic-fetch";

import Layout from "./src/components/layout";
import Theme from "./src/components/theme";

const client = new ApolloClient({
  fetch,
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    setContext(
      (req, prev) => {
        return ({
          headers: {
            ...prev.headers,
            "X-WP-Nonce": req.variables.nonce ? req.variables.nonce : '',
          }
        })
      }
      ),
      new HttpLink({
        uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
        credentials: 'include',
      }),
    ]),
  });
  
  // Wraps every page in a component
export function wrapPageElement({ element, props }) {

  return (
    <Theme>
      <Layout {...props}>{element}</Layout>
    </Theme>
  );
}

export function wrapRootElement({ element }) {
  return <ApolloProvider client={client}>{element}</ApolloProvider>;
}
