import { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { rem } from "polished";
import theme from ".";

const varibles = css`
  :root {
    --default-font-size: ${rem("16px")};
    --text-size: ${rem("18px")};
    --text-line-height: 1.6666666667;
    --gutter: ${rem(theme.gutter.sm)};
    --header-angle: 18px;
    --hatched-border-width: 25;
    --hero-additional-padding: 0;

    ${breakpoint("tablet-sm")`
      --gutter: ${rem(theme.gutter.md)};
      --header-angle: 43px;
    `};

    ${breakpoint("tablet-lg")`
      --text-size: ${rem("20px")};
      --text-line-height: 1.6;
      --text-letter-spacing: ${rem("-0.2px")};
      --header-angle: 58px;
    `};

    ${breakpoint("desktop-sm")`
      --gutter: 0;
    `};

    ${breakpoint("desktop-md")`
      --header-angle: 85px;
    `};

    ${breakpoint("start", "tablet-sm")`
      --hatched-border-width: 16;
    `}
  }
`;

export default varibles;
