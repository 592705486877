import { rgba } from "polished";

const colors = {
  purpleDark: "#241947",
  purpleMid: "#4B3F72",
  purpleBright: "#5E0CAA",
  lilac: "#C9B8FF",
  lilacLight: "#E4DBFF",
  teal: "#23AFBB",
  green: "#26F4AD",
  turquoise: "#00FFE7",
  grey: "#F1F1F4",
  white: "#FFFFFF",
  black: "#000000",
};

const gradients = {
  purple: function (deg = "90deg") {
    return `linear-gradient(${deg}, ${colors.purpleMid} 30%, #1D143B) 100%`;
  },
  blue: function (deg = "90deg") {
    return `linear-gradient(${deg}, #8EFFF8, #68C6D9)`;
  },
  teal: function (deg = "90deg") {
    return `linear-gradient(${deg}, #25B2BE, #107A89)`;
  },
  tealToWhite: `linear-gradient(180deg, #25B2BE 40%, #107A89 60%, #FFFFFF 60%)`,
};

const colorMapping = {
  primary: colors.purpleDark,
  secondary: colors.turquoise,
  panels: {
    light: colors.white,
    lightPurple: colors.lilac,
  },
  border: {
    light: colors.white,
    medium: colors.purpleBright,
  },
  table: {
    heading: {
      background: colors.purpleDark,
      text: colors.white,
    },
    rows: {
      odd: rgba(colors.grey, 0.2),
      even: colors.grey,
    },
  },
  text: {
    light: colors.white,
    dark: colors.purpleDark,
    black: colors.black,
  },
  shadows: `0 20px 20px 0px rgba(0, 0, 0, 0.2)`,
};

const allColors =  {
  ...colors,
  ...colorMapping,
  gradients,
};

export default allColors;
