import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { rem } from "polished";

import Link from "@components/link";
import { Inner } from "@components/layouts";

const linkStyles = css`
  display: block;
  position: relative;
  width: 100%;
  font-size: ${rem("19px")};
  line-height: 1.3684210526;
  padding: ${rem("15px")} ${rem("5px")} ${rem("15px")} ${rem("35px")};
  color: ${(props) => props.theme.colors.text.light};
  text-decoration: none;

  ${breakpoint("tablet-lg", "desktop-md")`
    font-size: ${rem("16px")};
    line-height: 1.625;
  `};

  ${breakpoint("tablet-lg")`
    padding: ${rem("35px")} ${rem("14px")} ${rem("10px")};
  `};

  ${breakpoint("desktop-md")`
    padding: ${rem("35px")} ${rem("28px")} ${rem("25px")};
  `};

  :focus {
    color: ${(props) => props.theme.colors.text.light};
  }

  :hover {
    color: ${(props) => props.theme.colors.secondary};
  }

  /* Circles */
  :before {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    width: ${rem("16px")};
    height: ${rem("16px")};
    border-radius: 50%;
    content: "";
    transform: translateY(-50%);
    border-color: transparent;

    ${breakpoint("tablet-lg")`
      top: 8px;
      left: 50%;
      border: 2px solid ${(props) => props.theme.colors.border.light};
      transform: translate(0, -50%);
    `};
  }

  &&[aria-current="page"] {
    ${breakpoint("tablet-lg")`
     text-decoration: underline;
      text-decoration-color: ${(props) => props.theme.colors.secondary};
      text-decoration-thickness: 2px;
      text-underline-offset: 8px;
    `};

    :before {
      background: ${props => props.theme.colors.text.light};
    }
  }
`;

export const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  min-height: ${rem("96px")};
  padding: ${rem("10px")} 0 ${rem("20px")};

  ${breakpoint("tablet-sm")`
    min-height: ${rem("130px")};
    padding: ${rem("25px")} 0 ${rem("20px")};
  `};

  ${breakpoint("tablet-lg")`
    min-height: ${rem("175px")};
  `};

  ${breakpoint("desktop-md")`
    min-height: ${rem("230px")};
    padding: ${rem("50px")} 0 ${rem("45px")};
  `};

  ::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
    background-color: ${props => props.theme.colors.primary};
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - var(--header-angle)),
      0% 100%
    );

    ${breakpoint("tablet-sm")`
      background-color: transparent;
      background-image: linear-gradient(
        43deg, 
        rgba(75,63,114,0.00) 0%, 
        #1D143B 100%
      );
    `};
  }

  ${(props) =>
    props.isOpen &&
    css`
      ${breakpoint("start", "tablet-lg")`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        ::before {
          background: ${(props) => props.theme.colors.primary};
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
      `};
    `};
`;

export const HeaderInner = styled(Inner)`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  ${breakpoint("tablet-sm")`
    align-items: flex-start;
  `};
`;

export const Logo = styled(Link)`
  display: block;
  width: ${rem("139px")};
  height: ${rem("60px")};
  z-index: 10;
  color: ${(props) => props.theme.colors.secondary};
  outline-offset: 5px;

  ${breakpoint("tablet-sm")`
    width: ${rem("166px")};
    height: ${rem("68px")};
  `};

  > * {
    /* Resets h1 when used on homepage */
    font-size: initial;
  }
`;

export const Navigation = styled.nav`
  z-index: 1;
  
  ${breakpoint("start", "tablet-lg")`
    position: fixed;
    top: ${rem("100px")};
    left: 0;
    width: 100%;
    height: calc(100% - ${rem("100px")});
    overflow: auto;
  `};

  ${breakpoint("tablet-lg")`
    position: relative;
  `};

  a {
    color: ${(props) => props.theme.colors.text.light};
  }
`;

export const ParentNavigation = styled.ul`
  position: relative;
  overflow-y: auto;
  list-style-type: none;
  padding: 0 var(--gutter) ${rem("50px")};
  margin: 0;
  color: ${(props) => props.theme.colors.text.light};

  ${breakpoint("tablet-lg")`
    display: flex;
    align-items: center;
    overflow: visible;
    padding: 0;
  `};
`;

export const ParentNavigationItem = styled.li`
  position: relative;
  padding: 0;
  margin: 0;

  /* Lines */
  ::before,
  ::after {
    display: block;
    position: absolute;
    top: 0;
    left: ${rem("7px")};
    z-index: 1;
    width: 2px;
    height: 100%;
    content: "";
    background: ${(props) => props.theme.colors.border.light};

    ${breakpoint("tablet-lg")`
      top: 8px;
      left: 8px;
      width: calc(50% - 8px);
      height: ${rem("2px")};
    `};
  }

  ::after {

    ${breakpoint("tablet-lg")`
      right: -8px;
      left: initial;
    `};
  }

  :first-child,
  :last-child {
    ::before {
      ${breakpoint("start", "tablet-lg")`
        height: calc(100% - 30px);
      `};

      ${breakpoint("tablet-lg")`
        width: 50%;
      `};
    }

    ::after {
      content: none;
    }

    a,
    button {
      :before {
        background: ${(props) => props.theme.colors.primary};
        border: 2px solid ${(props) => props.theme.colors.border.light};

        ${breakpoint("tablet-lg")`
          background: transparent;
        `};
      }
    }
  }

  :first-child {
    ::before {
      ${breakpoint("start", "tablet-lg")`
          top: 30px;
      `};

      ${breakpoint("tablet-lg")`
        right: -16px;
        left: initial;  
      `};
    }
  }

  :last-child {
    ::before {
      ${breakpoint("start", "tablet-lg")`
        bottom: 30px;
      `};

      ${breakpoint("tablet-lg")`
        left: 0;
      `};
    }
  }
`;

export const NavigationLink = styled(Link)`
  ${linkStyles}
`;

export const NavigationButton = styled.button`
  ${linkStyles}

  ${breakpoint("tablet-lg")`
    padding-right: ${rem("20px")};
  `};

  ${breakpoint("desktop-sm")`
    padding-right: ${rem("38px")};
  `};

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    width: ${rem("13px")};
    height: ${rem("13px")};
    stroke: currentColor;
    transform: translateY(-50%);
    
    ${breakpoint("tablet-lg")`
      top: calc(50% + ${rem("6px")});
      right: 0.5%;
      transform: translateY(0);
    `};


    ${breakpoint("desktop-md")`
      top: 50%;
      right: ${rem("10px")};
    `};
  }

  ${props => props.isExpanded && css`
    svg {
      transform-origin: center center;
      transform: rotate(180deg);
    }
  `};
`;

export const ChildNavigation = styled.ul`
  list-style-type: none;
  position: relative;
  padding: 0;
  margin: 0 0 0 ${rem("35px")};

  ${breakpoint("tablet-lg")`
    position: absolute;
    left: 50%;
    width: ${rem('200px')};
    margin: 0;
    padding: ${rem("15px")} 0 ${rem("20px")};
    background: ${(props) => props.theme.colors.primary};
    transform: translateX(-50%);
  `};

  ${breakpoint("desktop-sm")`
    width: ${rem('250px')};
  `};
`;

export const ChildNavigationItem = styled.li`
  position: relative;
  padding: 0;
  margin: 0;

  ::before {
    display: block;
    position: absolute;
    top: 0;
    left: ${rem("7px")};
    z-index: 1;
    width: 2px;
    height: 100%;
    content: "";
    background: ${(props) => props.theme.colors.border.light};

    ${breakpoint("tablet-lg")`
      display: none;
      visibility: hidden;
    `};
  }

  :first-child,
  :last-child {
    ::before {
      height: 50%;
    }
  }

  :only-child {
    ::before {
      display: none;
    }
  }

  :first-child {
    ::before {
      top: initial;
      bottom: 0;
    }
  }
`;

export const ChildNavigationLink = styled(Link)`
  display: block;
  position: relative;
  width: 100%;
  padding: ${rem("12px")} ${rem("5px")} ${rem("12px")} ${rem("30px")};
  font-size: ${rem("19px")};
  line-height: 1.3684210526;
  color: inherit;
  text-decoration: none;
 
  ${breakpoint("tablet-sm")`
    padding: ${rem("5px")} ${rem("5px")} ${rem("9px")} ${rem("30px")};
  `};

  ${breakpoint("tablet-lg")`
    padding: ${rem("15px")} ${rem("5px")} ${rem("17px")} ${rem("42px")};
    font-size: ${rem("16px")};
    line-height: 1.25;
    letter-spacing: ${rem("-0.22px")};
  `};

  /* Circles */
  ::before {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    z-index: 2;
    width: ${rem("16px")};
    height: ${rem("16px")};
    border-radius: 50%;
    content: "";
    transform: translateY(-50%);
    border-color: transparent;
    background: ${(props) => props.theme.colors.primary};
    border: 2px solid ${(props) => props.theme.colors.border.light};

    ${breakpoint("tablet-lg")`
      left: ${rem("14px")};
    `};
  }

  :hover {
    color: ${props => props.theme.colors.secondary};
  }

  &&[aria-current="page"] {

    ::before {
      background: ${props => props.theme.colors.text.light};
    }
  }
`;

export const Hamburger = styled.button`
  display: block;
  position: relative;
  width: ${rem("38px")};
  height: ${rem("38px")};
  padding: ${rem("9px")} ${rem("6px")};
  border: 2px solid ${(props) => props.theme.colors.border.light};
  transform: translateY(-2px);
  z-index: 10;

  ${breakpoint("tablet-lg")`
    display: none;
    visibility: hidden;
  `};

  > span {
    display: block;
    position: absolute;
    width: calc(100% - 12px);
    height: ${rem("2px")};
    background: ${(props) => props.theme.colors.border.light};
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 9px;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(4) {
      bottom: 9px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &[aria-expanded="true"] {
    > span {
      &:nth-child(1) {
        top: 50%;
        left: 50%;
        width: 0%;
        transform: translate(-50%, -50%);
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 50%;
        left: 50%;
        width: 0%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;
