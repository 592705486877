import { rem } from "polished";
import { css } from "styled-components";

import theme from ".";

const buttons = css`
  .button {
    display: block;
    padding: ${rem("12px")} ${rem("42px")};
    font-family: ${theme.fonts.buttons};
    color: ${theme.colors.purpleDark};
    font-size: ${rem("19px")};
    line-height: ${rem("26px")};
    letter-spacing: ${rem("-0.211111px")};
    position: relative;
    top: 0px;
    font-weight: 700;
    border-radius: ${rem("25px")};
    transition-timing-function: ease-in-out;

    &:not(.button-solidcolor) {
      background: rgb(109, 255, 155);
      background: linear-gradient(
        63deg,
        rgba(109, 255, 155, 1) 0%,
        rgba(39, 204, 209, 1) 50%,
        rgba(109, 255, 155, 1) 100%
      );
      background-size: 200% 100%;
      background-position: left top;
      transition: background-position 0.15s, top 0.05s;
    }

    &:hover:not(.button-solidcolor),
    &:active:not(.button-solidcolor) {
      background-position: right top;
    }
    &:active {
      top: 3px;
    }

    &.button-large {
      padding: ${rem("22px")} ${rem("35px")};
      font-size: ${rem("24px")};
      border-radius: ${rem("35px")};
      letter-spacing: ${rem("-0.266667px")};
    }

    &.button-solidcolor {
      color: ${theme.colors.white};
      background-color: ${theme.colors.purpleBright};
      transition: background-color 0.3s, top 0.05s;
      &:hover,
      &:active {
        background-color: ${theme.colors.purpleDark};
      }
    }

    &.button-reduced {
      padding: ${rem("12px")} ${rem("20px")};
      text-align: center;
      text-decoration: none;
    }

    &.button-download {
      text-decoration: none;

      ::after {
        display: inline-block;
        width: 18px;
        height: 20px;
        margin-left: 12px;
        content: "";
        background: url("/download.svg") center center no-repeat;
        background-size: cover;
        vertical-align: middle;
      }
    }

    &.button-external {

      ::after {
        display: inline-block;
        width: 18px;
        height: 20px;
        margin-left: 12px;
        content: "";
        background: url("/external.svg") center center no-repeat;
        background-size: cover;
        vertical-align: middle;
      }
    }
  }
  /* CIVIC BUTTONS */
  #ccc #ccc-icon {
      fill: #5E0CAA !important;
  }
`;

export default buttons;
