const config = {
  fonts: {
    heading: "Space Grotesk, sans-serif",
    body: "Space Grotesk, sans-serif",
    buttons: "Space Grotesk, sans-serif",
  },
  container: {
    max: "1200px",
  },
  gutter: {
    sm: "15px",
    md: "35px",
  },
  spacing: {},
};

export default config;
