import React from "react";
import Parser from "html-react-parser";

import { StyledLink } from "./style";

const LinkComponent = ({ url, label = "", target, children, className, hideIcon }) => {
  const external = target === "_blank" || target === "blank";
  const content = children || Parser(label);
  const classes = [className || ""];
  
  if (external && hideIcon !== true) {
    classes.push("button-external");
  }

  if (external) {
    return (
      <a
        href={url}
        className={classes.join(" ")}
        target={target}
        rel="noopener noreferrer"
      >
        {content}
      </a>
    );
  }

  return (
    <StyledLink to={url} className={className}>
      {content}
    </StyledLink>
  );
};

export default LinkComponent;
