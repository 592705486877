import React, { useState, useEffect, useRef, forwardRef } from "react";
import { globalHistory } from "@reach/router";
import FocusLockUI from "react-focus-lock/UI";
import { sidecar } from "use-sidecar";

import { formatMenuData } from "@helpers/index";

import {
  Header,
  HeaderInner,
  Logo,
  Navigation,
  NavigationLink,
  NavigationButton,
  ParentNavigation,
  ParentNavigationItem,
  ChildNavigation,
  ChildNavigationItem,
  ChildNavigationLink,
  Hamburger,
} from "./style";

import Arrow from "@images/arrow.svg";
import LogoAsset from "@images/logo.svg";

const FocusLockSidecar = sidecar(() =>
  import(/* webpackPrefetch: true */ "react-focus-lock/sidecar")
);

const HeaderComponent = ({ title, nav, location }) => {
  const SiteHeadingLevel = (location.pathname === '/') ? 'h1' : 'span';
  // WP shows flat nav structure
  // Remove any item that has a parent ID and then will render
  const formattedNavItems = formatMenuData(nav);

  const [showNavigation, setShowNavigation] = useState(false);
  const [showChildNavigation, setChildNavigation] = useState(null);
  const [menuLock, setMenuLock] = useState(true);

  const headerRef = useRef(null);

  // FUNCTIONS
  const handleMenuView = () => {
    const showOnDesktop = window.matchMedia("(min-width: 1024px)").matches;
    setShowNavigation(showOnDesktop);
    setChildNavigation(null);
  };

  // Add Events to set correct menu view
  useEffect(() => {
    handleMenuView();
    window.addEventListener("resize", handleMenuView, false);

    return () => {
      window.removeEventListener("resize", handleMenuView, false);
    };
  }, []);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        handleMenuView();
      }
    });
  }, [showNavigation, showChildNavigation]);

  return (
    <Header ref={headerRef} isOpen={showNavigation}>
      <HeaderInner>
        <Logo url="/">
          <SiteHeadingLevel>
            <LogoAsset aria-hidden="true" />
            <span className="sr-only">{title}</span>
          </SiteHeadingLevel>
        </Logo>
        <NavigationComponent
          ref={headerRef}
          navItems={formattedNavItems}
          showNavigation={showNavigation}
          setShowNavigation={setShowNavigation}
          showChildNavigation={showChildNavigation}
          setChildNavigation={setChildNavigation}
          menuLock={menuLock}
          setMenuLock={setMenuLock}
          location={location}
        />
      </HeaderInner>
    </Header>
  );
};

const NavigationComponent = forwardRef(
  (
    {
      navItems,
      showNavigation,
      setShowNavigation,
      showChildNavigation,
      setChildNavigation,
      menuLock,
      setMenuLock,
      location,
    },
    ref
  ) => {
    const handleHamburgerTrigger = () => {
      setShowNavigation(!showNavigation);
    };

    // Remove tab lock when clicking out side of the menu
    useEffect(() => {
      const headerRef = ref.current;
      const outsideMenuEvent = () => {
        if (
          window.matchMedia("(min-width: 1024px)").matches &&
          showNavigation
        ) {
          setChildNavigation(null);
        }
      };

      headerRef.addEventListener("mouseleave", outsideMenuEvent, false);

      return () => {
        headerRef.removeEventListener("mouseleave", outsideMenuEvent, false);
      };
    }, [showNavigation, setChildNavigation, ref]);

    return (
      <>
        <Hamburger
          aria-label="Toggle Navigation"
          aria-expanded={showNavigation}
          onClick={() => handleHamburgerTrigger()}
        >
          <>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </>
        </Hamburger>
        {navItems && showNavigation && (
          <Navigation>
            <FocusLockUI disabled={menuLock} sideCar={FocusLockSidecar}>
              <ParentNavigation>
                {navItems.map((navItem, index) => {
                  const isExpanded = (showChildNavigation === index);

                  return (
                    <ParentNavigationItem key={navItem.id}>
                      {navItem?.childItems?.nodes.length > 0 ? (
                        <NavigationButton
                          aria-expanded={isExpanded}
                          isExpanded={isExpanded}
                          aria-current={
                            location.pathname.includes(navItem.url)
                              ? "page"
                              : ""
                          }
                          onClick={() =>
                            (showChildNavigation === index)
                              ? setChildNavigation(null)
                              : setChildNavigation(index)
                          }
                        >
                          {navItem.label}
                          <Arrow aria-hidden="true" />
                        </NavigationButton>
                      ) : (
                        <NavigationLink {...navItem} />
                      )}

                      {navItem?.childItems?.nodes &&
                        showChildNavigation === index && (
                          <ChildNavigation>
                            {navItem?.childItems?.nodes?.map((childItem) => (
                              <ChildNavigationItem key={childItem.id}>
                                <ChildNavigationLink {...childItem} />
                              </ChildNavigationItem>
                            ))}
                          </ChildNavigation>
                        )}
                    </ParentNavigationItem>
                  );
                })}
              </ParentNavigation>
            </FocusLockUI>
          </Navigation>
        )}
      </>
    );
  }
);

export default HeaderComponent;
