import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Header from "@components/header";
import Footer from "@components/footer";

export default function Layout({ children, location }) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
      wp {
        generalSettings {
          url
        }
        footerSettings {
          footerSettings {
            partnersTitle
            partners {
              partnerLink {
                target
                title
                url
              }
              partnerLogo {
                previewFile: sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
            socialTitle
            socialProfiles {
              socialProfile {
                target
                label: title
                url
              }
            }
          }
        }
      }
      headerMenuQuery: wpMenu(name: { eq: "Primary" }) {
        ...menu
      }
      footerMenuQuery: wpMenu(name: { eq: "Footer" }) {
        ...menu
      }
    }

    fragment menu on WpMenu {
      menuItems {
        nodes {
          id
          parentId
          target
          label
          url: path
          childItems {
            nodes {
              id
              label
              parentId
              url: path
              target
            }
          }
        }
      }
    }
  `);

  const { title } = data?.site?.siteMetadata;
  const headerMenu = data?.headerMenuQuery?.menuItems?.nodes || [];
  const footerMenu = data?.footerMenuQuery?.menuItems?.nodes || [];
  const footerContent = data?.wp.footerSettings?.footerSettings;

  return (
    <>
      <a href="#content" className="skip-link">
        Skip to content
      </a>
      <Header title={title} nav={headerMenu} location={location} />
      <main id="content">{children}</main>
      <Footer content={footerContent} nav={footerMenu} />
    </>
  );
}
