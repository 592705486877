import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import base from "./base";
import varibles from "./varibles";
import typography from "./typography";
import buttons from "./buttons"

const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${varibles};
  ${base};
  ${typography};
  ${buttons};
`;

export default GlobalStyle;
