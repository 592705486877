const breakpoints = {
  'start': 0,
  'phone': 320,
  'phone-lg': 500,
  'tablet-sm': 768,
  'tablet-lg': 1024,
  'desktop-sm': 1280,
  'desktop-md': 1440,
  'desktop-lg': 1600,
  'desktop-max': 1920,
};

export default breakpoints;
