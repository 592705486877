import { rem } from "polished";
import { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

import theme from ".";

const typography = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  [class^="heading"] {
    margin: 0;
    font-weight: 700;
    font-family: ${theme.fonts.heading};
    color: ${theme.colors.purpleDark || "black"};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  .heading-xl {
    font-weight: 400;
    font-size: ${rem("48px")};
    line-height: 0.9166666667;

    ${breakpoint("tablet-sm")`
      font-size: ${rem("60px")};
      line-height: 1.1538461538;
    `};

    ${breakpoint("tablet-sm")`
      font-size: ${rem("70px")};
      line-height: 0.8571428571;
    `};

    ${breakpoint("desktop-md")`
      font-size: ${rem("120px")};
    `};
  }

  h2,
  .heading-lg {
    font-size: ${rem("26px")};
    line-height: 1.3076923077;

    ${breakpoint("tablet-sm")`
      font-size: ${rem("28px")};
      line-height: 1.2857142857;
    `};

    ${breakpoint("tablet-lg")`
      font-size: ${rem("32px")};
      line-height: 1.1538461538;
    `};

    ${breakpoint("desktop-md")`
      font-size: ${rem("40px")};
      line-height: 1.3;
    `};
  }

  h3,
  .heading-md {
    font-size: ${rem("24px")};
    line-height: 1.3333333333;

    ${breakpoint("tablet-sm")`
      font-size: ${rem("26px")};
      line-height: 1.3076923077;
    `};
    
    ${breakpoint("tablet-lg")`
      font-size: ${rem("28px")};
      line-height: 1.3571428571;
    `};

    ${breakpoint("desktop-md")`
      font-size: ${rem("32px")};
      line-height: 1.25;
      letter-spacing: ${rem("-0.42px")}
    `};
  }

  h4,
  .heading-sm {
    font-size: ${rem("22px")};
    line-height: 1.2727272727;

    ${breakpoint("tablet-lg")`
      font-size: ${rem("24px")};
      line-height: 1.3333333333;
    `};

    ${breakpoint("desktop-md")`
      font-size: ${rem("28px")};
      line-height: 1.2857142857;
    `};
  }

  h5,
  .heading-xs {
    font-size: ${rem("18px")};
    line-height: 1.3333333333;

    ${breakpoint("tablet-sm")`
      font-size: ${rem("20px")};
      line-height: 1.5;
    `};

    ${breakpoint("tablet-lg")`
      font-size: ${rem("22px")};
      line-height: 1.3636363636;
    `};

    ${breakpoint("desktop-md")`
      font-size: ${rem("24px")};
      line-height: 1.3333333333;
    `};
  }

  h6,
  .heading-xxs {
    font-size: ${rem("16px")};
    line-height: 1.375;

    ${breakpoint("tablet-sm")`
      font-size: ${rem("18px")};
      line-height: 1.3333333333;
    `};

    ${breakpoint("tablet-lg")`
      font-size: ${rem("20px")};
      line-height: 1.3;
    `};
  }
`;

export default typography;
