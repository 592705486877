import { css } from "styled-components";
import { rem } from "polished";
import breakpoint from "styled-components-breakpoint";
import theme from ".";

const base = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: inherit;
    font-family: ${theme.fonts.body};
    color: ${theme.fonts.primary};
    background: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
  }

  :focus {
    outline: currentColor 1px dashed;
    outline-offset: -1px;
  }

  small {
    font-size: 85%;
  }

  b,
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
    color: ${theme.colors.primary};
  }

  cite {
    font-style: normal;
  }

  pre {
    padding: ${rem("20px")};
    font-size: ${rem("14px")};
  }

  hr {
    display: block;
    position: relative;
    background: none;
    border: 1px solid ${(props) => props.theme.colors.primary};
    width: calc(100% - 32px);
    margin: ${rem("20px")} auto;

    ${breakpoint("tablet-sm")`
      margin: ${rem("40px")} auto;
    `};

    ::before,
    ::after {
      display: block;
      position: absolute;
      top: ${rem("-7px")};
      width: ${rem("14px")};
      height: ${rem("14px")};
      border: 1px solid ${(props) => props.theme.colors.primary};
      border-radius: 50%;
      background: ${(props) => props.theme.colors.panels.light};
      content: "";
    }

    ::before {
      left: ${rem("-15px")};
    }

    ::after {
      right: ${rem("-15px")};
    }
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0;
  }

  p {
    font-size: var(--text-size);
    line-height: var(--text-line-height);
    letter-spacing: var(--text-letter-spacing);
    margin: 0 0 ${rem("20px")};
    color: ${theme.colors.purpleDark};

    &.small {
      --text-size: ${rem("16px")};
      --text-line-height: 1.625;

      ${breakpoint("tablet-lg")`
        --text-size: ${rem("18px")};
        --text-line-height: 1.6666666667;
      `};
    }
  }

  a {
    display: inline-block;
    position: relative;
    color: ${theme.colors.purpleBright};
    text-decoration: underline;

    &:hover {
      color: ${theme.colors.purpleDark};
    }
  }

  ol,
  ul {
    padding: 0;
    margin: 0 0 60px;
    line-height: var(--text-line-height);

    :only-child {
      margin-bottom: 0%;
    }

    li {
      position: relative;
      margin: 0 0 ${rem("20px")};
      font-size: var(--text-size);

      :last-child {
        margin-bottom: 0;
      }

      > ol,
      > ul {
        margin: ${rem("10px")} 0 0;
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    color: ${theme.colors.purpleBright};

    ${breakpoint("desktop-sm")`
      margin-bottom: ${rem("70px")}
    `};

    li {
      padding: 0 0 0 ${rem("41px")};
      font-weight: 500;

      ${breakpoint("desktop-sm")`
        margin: 0 0 ${rem("30px")};
      `};

      :last-child {
        margin-bottom: 0;
      }

      &::before {
        counter-increment: item;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        position: absolute;
        top: 0;
        left: 0;
        margin-right: ${rem("10px")};
        width: 100%;
        max-width: 31px;
        min-height: 31px;
        line-height: 1;
        border: 2px solid currentColor;
        border-radius: 50%;
        vertical-align: middle;
        content: counters(item, ".") " ";

        ${breakpoint("desktop-sm")`
          font-size: 20px;
        `};
      }

      li {
        &::before {
          font-size: ${rem("15px")};
        }
      }
    }
  }

  ul {
    color: ${theme.colors.primary};
    margin-left: 20px;

    li {
      ::marker {
        color: ${theme.colors.purpleBright};
      }
    }
  }

  table {
    font-size: var(--default-font-size);
    line-height: 1.25;
    text-align: left;

    thead {
      color: ${theme.colors.table.heading.text};
      background: ${theme.colors.table.heading.background};

      th {
        border-right: 1px solid;
      }
    }

    th,
    td {
      padding: ${rem("20px")} ${rem("20px")} ${rem("20px")};
    }

    td {
      color: ${theme.colors.text.dark};
    }

    tr {
      &:not(thead tr) {
        background: ${theme.colors.table.rows.odd};

        &:nth-child(even) {
          background: ${theme.colors.table.rows.even};
        }
      }

      td {
        border-bottom: 1px solid;

        &:not(:last-child) {
          border-right: 1px solid;
        }
      }
    }
  }

  .table-responsive {
    width: 100%;
    overflow: auto;
    margin: ${rem("35px")} auto;

    ${breakpoint("tablet-sm")`
      margin: ${rem("55px")} auto;
    `};

    > * {
      ${breakpoint("start", "tablet-sm")`
        width: 200% !important;
        height: auto !important;
      `}
    }
  }

  button {
    appearance: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    background: none;
    text-align: left;
    cursor: pointer;
  }

  /***********************************************************
  * TURN OFF ANIMATIONS/TRANSITIONS IF BROWSER SETTING ENABLED
  ************************************************************/

  * {
    @media (prefers-reduced-motion: reduce) {
      animation: none !important;
      transition: none !important;
    }
  }

  /***************************
  * SKIP LINK
  ****************************/
  .skip-link {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;
    padding: 4px;
    font-weight: 700;
    color: ${theme.colors.text.light};
    background: ${theme.colors.secondary.primary};
    transform: translateY(-100%);

    &:focus {
      color: ${theme.colors.text.light};
      transform: translateY(0%);
    }
  }

  /***********************
   * SCREEN READER ONLY
   ************************/

  .sr-only {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
  }


  /******************
  * Dreaded splodge
  *******************/
  .splodges {
    position: relative;

    > * {
      position: relative;
      z-index: 10;
    }
    
    ::before,
    ::after {
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      content: "";
      background-image: url("/paint-splodge.svg");
      background-repeat: no-repeat;
      background-size: 465px 335px;
      background-position: top 50px left;
      opacity: 0.02;

      ${breakpoint("tablet-sm")`
        background-size: 525px 350px;
        background-position: top 160px left;
      `};

      ${breakpoint("tablet-lg")`
        background-size: 650px 435px;
        background-position: top 130px left;
      `};

      ${breakpoint("desktop-sm")`
        background-size: 930px 620px;
        background-position: top 180px left;
      `};
    }

    ::before {

      ${breakpoint("tablet-sm")`
        width: 336px;
      `};

      ${breakpoint("tablet-lg")`
        width: 395px;
      `};

      ${breakpoint("desktop-sm")`
        width: 578px;
      `};
    }

    ::after {
      width: 100%;
      background-position: top 300px right -50px;

      ${breakpoint("tablet-sm")`
        width: 420px;
        background-position: top 420px left;
      `};

      ${breakpoint("tablet-lg")`
        width: 568px;
        background-position: top 380px left;
      `};

      ${breakpoint("desktop-sm")`
        width: 825px;
        background-position: top 438px left;
      `};
    }
  }

  /***********************
   * Embedded content
   ************************/

  .embed {
    position: relative;
    @supports (aspect-ratio: 16 / 9) {
      aspect-ratio: 16 / 9;
    }

    @supports not (aspect-ratio: 16 / 9) {
      padding: 56.25% 0 0 0;
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .wysiwyg {
    > * {
      :not(hr) {
        margin-bottom: ${rem("30px")};

        ${breakpoint("tablet-sm")`
        margin-bottom: ${rem("25px")};
      `};

        ${breakpoint("tablet-lg", "desktop-md")`
        margin-bottom: ${rem("30px")};
      `};
      }

      :first-child {
        :is(h1, h2) {
          margin-bottom: ${rem("35px")};

          ${breakpoint("tablet-lg")`
          margin-bottom: ${rem("55px")};
        `};

          ${breakpoint("desktop-md")`
          margin-bottom: ${rem("50px")};
        `};
        }
      }

      :last-child {
        margin-bottom: 0;
      }
    }

    p {
      margin-bottom: ${rem("20px")};

      + *:is(h1, h2, h3, h4, h5, h6) {
        margin-top:${rem("50px")};
        ${breakpoint("tablet-sm")`
        margin: ${rem("70px")} 0 ${rem("20px")};
      `};

        ${breakpoint("tablet-lg")`
        margin: ${rem("55px")} 0 ${rem("30px")};
      `};

        ${breakpoint("desktop-md")`
        margin: ${rem("50px")} 0 ${rem("25px")};
      `};
      }
    }

    // CUSTOM FORMATING

    .custom-format__phone,
    .custom-format__link,
    .custom-format__email {
      position: relative;
      display: block;
      padding-left: 40px;
      word-break: break-word;
      &:before {
        content: "";
        width: 31px;
        height: 31px;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }
    .custom-format__phone:before {
      background-image: url("/phone.svg");
    }
    .custom-format__link:before {
      background-image: url("/globe.svg");
    }
    .custom-format__email:before {
      background-image: url("/email.svg");
    }

    .custom-format__app-download-google,
    .custom-format__app-download-apple {
      width: 134px;
      height: 39px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;
      color: rgba(0, 0, 0, 0);
      a {
        color: rgba(0, 0, 0, 0);
      }
    }
    .custom-format__app-download-google {
      background-image: url("/download-google-app.svg");
    }
    .custom-format__app-download-apple {
      background-image: url("/download-apple-app.svg");
    }
    .custom-format__notice-box {
      display: flex;
      padding: ${rem("21px")} ${rem("19px")} ${rem("21px")} ${rem("25px")};
      background-color: ${theme.colors.lilacLight};
      margin: 0 0 1.25rem;
      position: relative;

      p {
        font-size: ${rem("20px")};
        line-height: ${rem("32px")};
        color: ${theme.colors.purpleDark};
        font-weight: 500;
        letter-spacing: ${rem("-0.2px")};
        margin-bottom: 0px;

        ${breakpoint("tablet-sm", "tablet-lg")`
            font-size: ${rem("18px")};
            line-height: ${rem("28px")};
            `}
        ${breakpoint("start", "tablet-sm")`
            font-size: ${rem("16px")};
            line-height: ${rem("26px")};
            `}
      }

      &:before {
        width: 38px;
        height: 38px;
        min-width: 38px;
        margin-right: ${rem("22px")};
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("/information.svg");
        content: "";

        ${breakpoint("start", "tablet-sm")`
          margin-right:${rem("16px")};
          width:28px;
          height:28px;
          min-width:28px;
        `}
      }
    }
  }
`;

export default base;
