import React from "react";
import { ThemeProvider } from 'styled-components';

import theme from '../theme';
import GlobalStyles from '../theme/global-styles';

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}
