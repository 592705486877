import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { rem } from "polished";

export const Inner = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: calc(100% - (var(--gutter) * 2));
  max-width: ${(props) => props.theme.container.max};
`;

export const NarrowInner = styled(Inner)`

  ${breakpoint("tablet-lg", "desktop-lg")`
    --gutter: ${rem("68px")};
  `};
`;

export const NarrowContentInner = styled(Inner)`
  max-width: 895px;

  ${breakpoint("tablet-lg")`
    --gutter: ${rem("115px")};
  `};
`;
