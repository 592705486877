import config from "./config";
import colors from "./colors";
import breakpoints from "./breakpoints";

const theme = {
  ...config,
  colors: { ...colors },
  breakpoints: { ...breakpoints },
};

export default theme;
