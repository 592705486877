/**
 * Correct WP menu structure.
 * WP menu structure returns all menu items at the same level.
 *
 * @param {object} menu - The menu object from WP.
 * @returns {object} - Formatted menu structure
 */

export const formatMenuData = (menu) => {
  return menu.filter((menuItem) => menuItem.parentId === null);
};

export const getSocialIcon = (url) => {
  const socialProfiles = [
    "Facebook",
    "Instagram",
    "Youtube",
    "Linkedin",
    "Twitter",
  ];
  let icon = null;

  socialProfiles.forEach((profile) => {
    if (url.includes(profile.toLowerCase())) {
      icon = profile;
    }
  });

  return icon;
};

export const hatchedBorders = (color = "black") => {
  // Needs converted to be encoded correctly
  const formattedColor = color.replace("#", "%23");
  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${formattedColor}' stroke-width='25' stroke-dasharray='1' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");`;
};


export const cleanIdFromText = (text) => {

  if (text === null || text === undefined) return;
  if (text && text.length === 0) return;
  return text
    .trim()
    .replaceAll(/\s/g, "-")
    .replaceAll(/[?.,/#!$%^&*;:{}=_`~()]/g, "")
    .toLowerCase();
}

export const prettySize = (value) => {
  const i = Math.floor(Math.log(value) / Math.log(1024));
  return !value && '0 Bytes' || (value / Math.pow(1024, i)).toFixed(2) + " " + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][i];
}
